<template>
  <a-drawer :title="titleText" placement="right" :width="'80vw'" :closable="true" @close="handleClose" :visible="showDrawer">
    <div class="mb-20">
      <a-input allowClear v-model="params.groupName" class="w-200 mr-10" placeholder='名称搜索'></a-input>
      <a-button class="mr-20" type="primary" @click="getHuaSmallCategoryList" icon="plus">搜索</a-button>
      <a-button @click="handleAddHuaSmallCategory" icon="plus">{{ titleText }}</a-button>
    </div>
    <HuaSmallCategoryTableList
        :list="data"
        :pagination="pagination"
        @changePage="handleChangePage"
        @success="getHuaSmallCategoryList"
    />
    <!--  编辑花钱小分类  -->
    <EditorHuaSmallCategoryPopup ref="editorHuaSmallCategoryPopupEl" @success="getHuaSmallCategoryList"/>
  </a-drawer>
</template>
<script>
import EditorHuaSmallCategoryPopup
  from "@/views/cmsPage/currencyMannage/coinWikiLeftCategoryManage/_components/TableList/_components/HuaSmallCategoryListPopup/_compoents/EditorHuaSmallCategoryPopup/index.vue"
import {getHuaSmallCategoryListApi} from "@/views/cmsPage/currencyMannage/coinWikiLeftCategoryManage/_apis"
import HuaSmallCategoryTableList
  from "@/views/cmsPage/currencyMannage/coinWikiLeftCategoryManage/_components/TableList/_components/HuaSmallCategoryListPopup/_compoents/HuaSmallCategoryTableList/index.vue"

export default {
  components: {EditorHuaSmallCategoryPopup, HuaSmallCategoryTableList},
  data() {
    return {
      data: [],
      showDrawer: false,
      titleText: '花钱小分类列表',
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        wikiCategoryId: undefined, // 一级分类
        topicId: undefined, // 二级分类
        groupName: undefined, // 三级分类名称
      },
      topicName: undefined
    }
  },
  methods: {
    /** 添加小分类 */
    handleAddHuaSmallCategory() {
      this.$refs.editorHuaSmallCategoryPopupEl.show('', {
        typeName: this.topicName, topicId: this.params.topicId})
    },
    /** 展示数据 */
    async show(data) {
      this.topicName = data.topicName
      this.titleText = '花钱小分类列表---' + data.topicName
      this.params.wikiCategoryId = data.wikiCategoryId
      this.params.topicId = data.id
      await this.getHuaSmallCategoryList()
      this.showDrawer = true
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getHuaSmallCategoryList();
    },
    /** 获取明细列表 */
    async getHuaSmallCategoryList() {
      this.$loading.show()
      const res = await getHuaSmallCategoryListApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
    /** 关闭明细 */
    handleClose() {
      this.showDrawer = false
    }
  }
}
</script>
<style scoped lang="scss">

</style>