<template>
  <div class="content">
    <div class="top">
      <a-input allowClear v-model="params.topicName" class="w-200 mb-5" placeholder='名称搜索'></a-input>
      <a-select allowClear showSearch class="ml-5 w-200 mb-5" v-model="params.wikiCategoryId" placeholder="百科分类">
        <a-select-option v-for="item of categoryTypeList" :key="item.id" :value="item.id">{{ item.categoryName }}</a-select-option>
      </a-select>
      <a-select allowClear showSearch class="ml-5 w-200 mb-5" v-model="params.status" placeholder="是否可有">
        <a-select-option v-for="item of isCanUseStatusList" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
      </a-select>
      <a-button class="mb-5" type="primary" @click="search">搜索</a-button>
      <a-button class="mb-5" @click="handleAdd" icon="plus">添加分类</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          :categoryTypeList="categoryTypeList"
          @changePage="handleChangePage"
          @success="getList"
      />
    </div>
<!--  增加分类  -->
    <EditorLeftCategoryPopup
        ref="editorLeftCategoryPopupEl"
        :categoryTypeList="categoryTypeList"
        @success="getList"
    />
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/currencyMannage/coinWikiLeftCategoryManage/_components/TableList/index.vue"
import {
  getCoinWikiLeftCategoryListApi,
  getCoinWikiTopBigCategoryListApi
} from "@/views/cmsPage/currencyMannage/coinWikiLeftCategoryManage/_apis"
import EditorLeftCategoryPopup
  from "@/views/cmsPage/currencyMannage/coinWikiLeftCategoryManage/_components/EditorLeftCategoryPopup/index.vue"
import {isCanUseStatusList} from "@/views/cmsPage/currencyMannage/_data"

export default {
  components: {
    TableList, EditorLeftCategoryPopup
  },
  data() {
    return {
      data: [],
      isCanUseStatusList,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      categoryTypeList: [],
      params: {
        pageNum: 1,
        pageSize: 10,
        topicName: undefined, // 名称查询
        wikiCategoryId: undefined, // 分类名称
        status: undefined, // 状态搜索
      },
    };
  },
  async created() {
    this.params.wikiCategoryId = this.$route.query.topCategoryId
    await this.getList()
    await this.getCoinCategoryList()
  },
  methods: {
    /** 添加数据 */
    handleAdd() {
      this.$refs.editorLeftCategoryPopupEl.show()
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList();
    },
    /** 搜索 */
    search() {
      this.pagination.params = 1
      this.params.pageNum = 1
      this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getCoinWikiLeftCategoryListApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
    /** 获取分类列表 */
    async getCoinCategoryList() {
      const res = await getCoinWikiTopBigCategoryListApi()
      if (res.status !== '200') return
      this.categoryTypeList = res.data
    }
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  button {
    margin-left: 10px;
  }
}
</style>
