
/** 订单列表 */
export const coinWikiLeftCategoryTableColumns = [
    { title: "分类名称", align: "center", scopedSlots: {customRender: 'itemCategoryNameSlot'}, width: 200 },
    { title: "分类描述", align: "center", scopedSlots: {customRender: 'itemCategoryDescSlot' }, width: 250 },
    { title: "排序", align: "center", dataIndex: 'topicSort', width: 100 },
    { title: "创建时间", align: "center", dataIndex: 'createTime', width: 120 },
    { title: "更新时间", align: "center", dataIndex: 'updateTime', width: 120 },
    { title: "是否可用", align: "center", scopedSlots: {customRender: 'itemStatusSlot' }, width: 150 },
    { title: "操作相关", align: "center", scopedSlots: {customRender: 'itemActionSlot'}, width: 200 }
]