<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--   分类名称   -->
      <div slot="itemCategoryNameSlot" slot-scope="row">
        <div class="font-weight-bold color-black">{{ row.groupName }}</div>
      </div>
<!--   可用状态   -->
      <div slot="itemStatusSlot" slot-scope="row">
        <div class="font-weight-bold" :class="row.status ? 'color-green' : 'color-red'">
          {{ row.status ? '可用' : '不可用' }}
        </div>
      </div>
<!--   操作   -->
      <div slot="itemActionSlot" slot-scope="row">
        <a-button type="primary" @click="handleEditorSmallCategory(row)">编辑</a-button>
      </div>
    </a-table>
<!--  编辑花钱小分类  -->
    <EditorHuaSmallCategoryPopup ref="editorHuaSmallCategoryPopupEl" @success="handleSuccess"/>
  </div>
</template>
<script>
import {
  huaSmallCategoryTableColumns
} from "@/views/cmsPage/currencyMannage/coinWikiLeftCategoryManage/_components/TableList/_components/HuaSmallCategoryListPopup/_data"
import EditorHuaSmallCategoryPopup
  from "@/views/cmsPage/currencyMannage/coinWikiLeftCategoryManage/_components/TableList/_components/HuaSmallCategoryListPopup/_compoents/EditorHuaSmallCategoryPopup/index.vue"

export default {
  components: {EditorHuaSmallCategoryPopup},
  emits: ['success', 'changePage'],
  props: ['pagination', 'list'],
  data() {
    return {
      tableList: huaSmallCategoryTableColumns,
    }
  },
  computed: {

  },
  methods: {
    /** 编辑花钱小分类 */
    handleEditorSmallCategory(row) {
      this.$refs.editorHuaSmallCategoryPopupEl.show(row, {})
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    /** 修改成功 */
    handleSuccess() {
      this.$emit('success')
    },
  }
}
</script>
<style lang="scss" scoped>
</style>