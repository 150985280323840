<template>
  <a-modal v-model="showPopup" :width='500' :title="titleText" @cancel="handleCancel" @ok="handleConfirm">
    <a-form-model ref="ruleForm" :model="params" v-bind="layout">
      <a-form-item label="小分类名称">
        <a-input
            allowClear
            v-model="params.groupName"
            class="w-200"
            placeholder='小分类名称'
        ></a-input>
      </a-form-item>
      <a-form-item label="分类排序">
        <a-input
            allowClear
            v-model="params.sort"
            type="number"
            class="w-200"
            placeholder='分类排序'
        ></a-input>
      </a-form-item>
      <a-form-item label="是否直接可使用">
        <a-radio-group name="radioGroup" :default-value="0" v-model="params.status">
          <a-radio :value="0">不可用</a-radio>
          <a-radio :value="1">可用</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import {updateBaseObjectWithComplex} from "@/untils/otherEvent"
import {
  postAddHuaSmallCategoryApi, postEditHuaSmallCategoryApi
} from "@/views/cmsPage/currencyMannage/coinWikiLeftCategoryManage/_apis"

export default {
  emits: ['success'],
  props: ['categoryTypeList'],
  data() {
    return {
      showPopup: false,
      titleText: '新增花钱小分类',
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
      },
      params: {
        id: undefined,
        groupName: undefined,
        sort: undefined,
        status: 0,
        topicId: undefined
      },
      isEditor: false,
    }
  },
  methods: {
    show(data, {typeName, topicId}) {
      if (data) {
        this.isEditor = true
        this.titleText = '编辑花钱小分类---' + data.topicName
        this.params = updateBaseObjectWithComplex(this.params, data)
      } else {
        this.params.topicId = topicId
        this.titleText = '添加花钱小分类---' + typeName
        this.isEditor = false
      }
      this.showPopup = true
    },
    /** 确定修改 */
    async handleConfirm() {
      if (!this.params.groupName) return this.$message.warning('分类名称不能为空')
      this.$loading.show()
      let res
      if (this.isEditor) {
        res = await postEditHuaSmallCategoryApi(this.params)
      } else {
        res = await postAddHuaSmallCategoryApi(this.params)
      }
      this.$loading.hide()
      if (res.status !== '200') return;
      this.$message.info('修改成功')
      this.$emit('success')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        id: undefined,
        groupName: undefined,
        sort: undefined,
        status: 0,
        topicId: undefined
      }
      this.showPopup = false
    }
  }
}
</script>