<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--    分类名称  -->
      <div slot="itemCategoryNameSlot" slot-scope="row">
        <div class="font-weight-bold">{{ row.topicName }}</div>
      </div>
<!--    分类名称  -->
      <div slot="itemCategoryDescSlot" slot-scope="row">
        {{ row.topicDesc }}
      </div>
<!--    是否可用  -->
      <div slot="itemStatusSlot" slot-scope="row">
        <div class="font-weight-bold" :class="row.status ? 'color-green' : 'color-red'">
          {{ row.status ? '可用' : '不可用' }}
        </div>
      </div>
<!--    操作  -->
      <div slot="itemActionSlot" slot-scope="row">
        <div><a-button type="primary" @click="handleEditorLeftCategory(row)">编辑</a-button></div>
        <div class="mt-5">
          <a-popconfirm title="确定删除？" @confirm="handleDeleteLeftCategory(row)">
            <a-button type="danger">删除</a-button>
          </a-popconfirm>
        </div>
        <div class="mt-5">
          <a-button v-if="row.wikiCategoryId === 4" @click="handleAddHuaSmallCategory(row)">花钱小分类</a-button>
        </div>
        <div class="mt-5"></div>
      </div>
    </a-table>
<!--  编辑分类  -->
    <EditorLeftCategoryPopup
        ref="editorLeftCategoryPopupEl"
        :categoryTypeList="categoryTypeList"
        @success="handleSuccess"
    />
<!--  小分类弹窗  -->
    <HuaSmallCategoryListPopup ref="huaSmallCategoryListPopupEl"/>
  </div>
</template>
<script>
import {coinWikiLeftCategoryTableColumns} from "@/views/cmsPage/currencyMannage/coinWikiLeftCategoryManage/_data"
import EditorLeftCategoryPopup
  from "@/views/cmsPage/currencyMannage/coinWikiLeftCategoryManage/_components/EditorLeftCategoryPopup/index.vue"
import {getDeleteCoinWikiLeftCategoryListApi} from "@/views/cmsPage/currencyMannage/coinWikiLeftCategoryManage/_apis"
import HuaSmallCategoryListPopup
  from "@/views/cmsPage/currencyMannage/coinWikiLeftCategoryManage/_components/TableList/_components/HuaSmallCategoryListPopup/index.vue"

export default {
  components: {EditorLeftCategoryPopup, HuaSmallCategoryListPopup},
  props: ['pagination', 'list', 'categoryTypeList'],
  emits: ['success', 'changePage'],
  data() {
    return {
      tableList: coinWikiLeftCategoryTableColumns,
    }
  },
  methods: {
    /** 显示花钱小分类 */
    handleAddHuaSmallCategory(row) {
      this.$refs.huaSmallCategoryListPopupEl.show(row)
    },
    /** 删除数据 */
    async handleDeleteLeftCategory(row) {
      this.$loading.show()
      const res = await getDeleteCoinWikiLeftCategoryListApi({id: row.id})
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('删除成功')
      this.handleSuccess()
    },
    /** 编辑分类 */
    handleEditorLeftCategory(row) {
      this.$refs.editorLeftCategoryPopupEl.show(row)
    },
    /** 成功 */
    handleSuccess() {
      this.$emit('success')
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
</style>