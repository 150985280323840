var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 500, title: _vm.titleText },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "ruleForm", attrs: { model: _vm.params } },
          "a-form-model",
          _vm.layout,
          false
        ),
        [
          _c(
            "a-form-item",
            { attrs: { label: "小分类名称" } },
            [
              _c("a-input", {
                staticClass: "w-200",
                attrs: { allowClear: "", placeholder: "小分类名称" },
                model: {
                  value: _vm.params.groupName,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "groupName", $$v)
                  },
                  expression: "params.groupName"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "分类排序" } },
            [
              _c("a-input", {
                staticClass: "w-200",
                attrs: {
                  allowClear: "",
                  type: "number",
                  placeholder: "分类排序"
                },
                model: {
                  value: _vm.params.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "sort", $$v)
                  },
                  expression: "params.sort"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "是否直接可使用" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { name: "radioGroup", "default-value": 0 },
                  model: {
                    value: _vm.params.status,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "status", $$v)
                    },
                    expression: "params.status"
                  }
                },
                [
                  _c("a-radio", { attrs: { value: 0 } }, [_vm._v("不可用")]),
                  _c("a-radio", { attrs: { value: 1 } }, [_vm._v("可用")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }