var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 500, title: _vm.titleText },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "ruleForm", attrs: { model: _vm.params } },
          "a-form-model",
          _vm.layout,
          false
        ),
        [
          _c(
            "a-form-item",
            { attrs: { label: "分类名称" } },
            [
              _c("a-input", {
                staticClass: "w-200",
                attrs: { allowClear: "", placeholder: "分类名称" },
                model: {
                  value: _vm.params.topicName,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "topicName", $$v)
                  },
                  expression: "params.topicName"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "分类名称" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "ml-5 w-200 mb-5",
                  attrs: {
                    allowClear: "",
                    showSearch: "",
                    placeholder: "大分类"
                  },
                  model: {
                    value: _vm.params.wikiCategoryId,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "wikiCategoryId", $$v)
                    },
                    expression: "params.wikiCategoryId"
                  }
                },
                _vm._l(_vm.categoryTypeList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.id, attrs: { value: item.id } },
                    [_vm._v(_vm._s(item.categoryName))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "分类排序" } },
            [
              _c("a-input", {
                staticClass: "w-200",
                attrs: {
                  allowClear: "",
                  type: "number",
                  placeholder: "分类排序"
                },
                model: {
                  value: _vm.params.topicSort,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "topicSort", $$v)
                  },
                  expression: "params.topicSort"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "描述说明" } },
            [
              _c("a-input", {
                staticClass: "w-100-w",
                attrs: {
                  allowClear: "",
                  type: "textarea",
                  "auto-size": { minRows: 4, maxRows: 6 },
                  placeholder: "分类描述说明（选填）"
                },
                model: {
                  value: _vm.params.topicDesc,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "topicDesc", $$v)
                  },
                  expression: "params.topicDesc"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "是否直接可使用" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { name: "radioGroup", "default-value": 0 },
                  model: {
                    value: _vm.params.status,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "status", $$v)
                    },
                    expression: "params.status"
                  }
                },
                [
                  _c("a-radio", { attrs: { value: 0 } }, [_vm._v("不可用")]),
                  _c("a-radio", { attrs: { value: 1 } }, [_vm._v("可用")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }