var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemCategoryNameSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold color-black" }, [
                  _vm._v(_vm._s(row.groupName))
                ])
              ])
            }
          },
          {
            key: "itemStatusSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  {
                    staticClass: "font-weight-bold",
                    class: row.status ? "color-green" : "color-red"
                  },
                  [_vm._v(" " + _vm._s(row.status ? "可用" : "不可用") + " ")]
                )
              ])
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleEditorSmallCategory(row)
                        }
                      }
                    },
                    [_vm._v("编辑")]
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c("EditorHuaSmallCategoryPopup", {
        ref: "editorHuaSmallCategoryPopupEl",
        on: { success: _vm.handleSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }