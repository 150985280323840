var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemCategoryNameSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(row.topicName))
                ])
              ])
            }
          },
          {
            key: "itemCategoryDescSlot",
            fn: function(row) {
              return _c("div", {}, [_vm._v(" " + _vm._s(row.topicDesc) + " ")])
            }
          },
          {
            key: "itemStatusSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  {
                    staticClass: "font-weight-bold",
                    class: row.status ? "color-green" : "color-red"
                  },
                  [_vm._v(" " + _vm._s(row.status ? "可用" : "不可用") + " ")]
                )
              ])
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleEditorLeftCategory(row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-5" },
                  [
                    _c(
                      "a-popconfirm",
                      {
                        attrs: { title: "确定删除？" },
                        on: {
                          confirm: function($event) {
                            return _vm.handleDeleteLeftCategory(row)
                          }
                        }
                      },
                      [
                        _c("a-button", { attrs: { type: "danger" } }, [
                          _vm._v("删除")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-5" },
                  [
                    row.wikiCategoryId === 4
                      ? _c(
                          "a-button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.handleAddHuaSmallCategory(row)
                              }
                            }
                          },
                          [_vm._v("花钱小分类")]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c("div", { staticClass: "mt-5" })
              ])
            }
          }
        ])
      }),
      _c("EditorLeftCategoryPopup", {
        ref: "editorLeftCategoryPopupEl",
        attrs: { categoryTypeList: _vm.categoryTypeList },
        on: { success: _vm.handleSuccess }
      }),
      _c("HuaSmallCategoryListPopup", { ref: "huaSmallCategoryListPopupEl" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }